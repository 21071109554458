import './DocumentGroupName.scss';
import { documentsApi } from 'api/documentsApi';
import ClosedFolder from 'assets/icons/documents/closed-folder.svg?react';
import OpenedFolder from 'assets/icons/documents/opened-folder.svg?react';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Tooltip } from 'components/InteractiveUIControls/Tooltip/Tooltip';
import { limitString } from 'components/Pipes/textPipes';
import { ModalActivationContext } from 'contexts';
import {
  type FC,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'services/mobile';
import { markDocumentsAsSeen } from 'store/slices';
import {
  type DocumentDTO,
  getItemsFromStorage,
} from 'types';

type DocumentGroupNameProps = {
  categoryId: string,
  documentGroupId: string,
  documentGroupName: string,
  documents: DocumentDTO[],
  isOpen: boolean,
  numberOfDocuments: number,
  numberOfNewDocuments: number,
};

export const DocumentGroupName: FC<DocumentGroupNameProps> = ({
  documentGroupName,
  documentGroupId,
  documents,
  categoryId,
  isOpen,
  numberOfDocuments,
  numberOfNewDocuments,
}) => {
  const dispatch = useDispatch();
  const { toggleDownloadsModal } = useContext(ModalActivationContext);

  const { loginAs } = getItemsFromStorage([
    'loginAs',
  ], sessionStorage);
  const markAsSeenClick = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    event?.stopPropagation();
    const filteredNewDocs = documents.filter((doc) => doc.userViewed === false);
    const documentIds = filteredNewDocs.map((doc) => doc._id);
    if (documentIds) {
      await documentsApi.viewDocuments({ documentIds });
    }

    dispatch(markDocumentsAsSeen({
      categoryId,
      documentGroupId,
      documentIds,
    }));
  };

  const onClickDownload = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    event?.stopPropagation();
    toggleDownloadsModal(true, documentGroupId);
  };

  return (
    <div
      className='nameContainer'
      style={isOpen ? {
        borderBottom: '2px solid rgba(221, 222, 223, 0.3)',
        borderRadius: '0.5rem 0.5rem 0 0',
      } : {}}
    >
      <div className='nameLeft'>
        {
          isOpen ?
            <OpenedFolder /> :
            <ClosedFolder />
        }
        <div className='documentGroupDetails'>
          <h3 className='documentGroupName'>
            {isMobile() ? limitString(documentGroupName, 24) : documentGroupName}
          </h3>
          <h3 className='documentTotalDocumentNumber'>
            {`${numberOfDocuments} documents`}
          </h3>
        </div>
      </div>
      {
        <div className='nameRight'>
          {Boolean(numberOfNewDocuments) &&
            numberOfNewDocuments > 0 && !loginAs && !isMobile() &&
            <Button
              className='fileViewButton'
              data-test='mark-file-as-seen'
              icon='left'
              iconid='mark-seen'
              onClick={(event) => markAsSeenClick(event)}
              size='medium'
              tooltipWrapperClass=' min-w-fit'
              type='grey'
            >Mark as seen </Button>}
          {<Button
            className='fileViewButton'
            data-test='download-file'
            icon='left'
            iconid='download-cloud'
            onClick={(event) => onClickDownload(event)}
            size='medium'
          >
            Download
          </Button>}
          {Boolean(numberOfNewDocuments) &&
            numberOfNewDocuments > 0 &&
            <div className='tooltip-wrapper black number-of-new-docs w-fit'>
              <Tooltip>{`${numberOfNewDocuments} new document${numberOfNewDocuments === 1 ? '' : 's'}`}</Tooltip>
              <div className='numDocumentsContainer'>
                <p className='numberOfDocuments'>
                  {numberOfNewDocuments}
                </p>
              </div>
            </div>}

        </div>
      }
    </div>
  );
};
